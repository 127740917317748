import { requireFun, charLenLimitConstructor } from '@/utils'
import { columnGroupTypeData } from '@/utils/constant/productConst'
import {
  FIELD_TYPE_DIC,
  ALI_EXPRESS_TYPE_DIC,
  ALI_UNIT_OPTION,
  AMAZON_TEXT_TYPE_DIC,
  FIELD_TYPE_INPUT,
  FIELD_TYPE_TEXTAREA
} from '@/utils/constant/fieldConst'

export const option = {
  size: 'small',
  labelWidth: 160,
  span: 13,
  column: [
    {
      label: '字段英文名称',
      prop: 'excelLocalLabelName',
      rules: [
        {
          validator: charLenLimitConstructor(1, 200)
        }
      ]
    },
    {
      label: '字段英文名称2',
      prop: 'excelFieldName',
      rules: [
        {
          validator: charLenLimitConstructor(1, 200)
        }
      ]
    },
    {
      label: '字段类型',
      prop: 'columnGroupType',
      type: 'select',
      dicData: columnGroupTypeData
    },
    {
      label: '中文名称',
      prop: 'labelName',
      rules: [
        {
          validator:charLenLimitConstructor(1, 200)
        }
      ]
    },
    {
      label: '输入样式',
      prop: 'fieldType',
      type: 'select',
      dicData: FIELD_TYPE_DIC,
      rules: [
        {
          required: true,
          message: requireFun('输入样式')
        }
      ],
      control(value) {
        const type = [FIELD_TYPE_INPUT, FIELD_TYPE_TEXTAREA].includes(+value)
        return {
          valueFormatType: {
            hide: !type
          },
          valueMaxLength: {
            hide: !type
          }
        }
      }
    },
    {
      label: '文本格式',
      prop: 'valueFormatType',
      dicData: AMAZON_TEXT_TYPE_DIC,
      type: 'select',
      rules: [
        {
          required: true,
          message: requireFun('文本格式')
        }
      ]
    },
    {
      label: '最大长度',
      prop: 'valueMaxLength',
      type: 'number',
      rules: [
        {
          required: true,
          message: requireFun('最大长度')
        },
        {
          validator: charLenLimitConstructor(1,5)
        }
      ]
    },
    {
      label: '是否默认系统数据',
      prop: 'isSetDefaultCode',
      rules: [
        {
          required: true,
          message: requireFun('是否默认系统数据')
        }
      ],
      control(value) {
        return {
          isNeedDefaultValue: {
            hide: !!value
          },
          customDefaultValue: {
            hide: !!value
          },
          isAllowSystemCode: {
            hide: !!value
          }
        }
      }
    },
    {
      label: '设置是否可插入属性',
      prop: 'isAllowSystemCode',
      rules: [
        {
          required: true,
          message: requireFun('设置是否可插入属性')
        }
      ]
    },
    {
      label: '是否预设默认值',
      prop: 'isNeedDefaultValue',
      rules: [
        {
          required: true,
          message: requireFun('是否预设默认值')
        }
      ],
      control(value, form) {
        const hide = !value || !!form.isSetDefaultCode
        hide && (form['customDefaultValue'] = '')
        return {
          customDefaultValue: { hide }
        }
      }
    },
    {
      label: '预设值',
      prop: 'customDefaultValue',
      rules: [
        {
          required: true,
          message: requireFun('预设值')
        }
      ]
    }
  ]
}
export const AliExpressOption = {
  size: 'small',
  labelWidth: 160,
  span: 13,
  column: [
    {
      label: '字段英文名称',
      prop: 'labelNameEn',
      rules: [
        {
          validator: charLenLimitConstructor(1, 200)
        }
      ]
    },
    {
      label: '字段英文名称2',
      prop: 'labelNameEnTwo',
      rules: [
        {
          validator: charLenLimitConstructor(1, 200)
        }
      ]
    },
    {
      label: '字段类型',
      prop: 'columnGroupType',
      type: 'select',
      dicData: columnGroupTypeData
    },
    {
      label: '中文名称',
      prop: 'labelName',
      rules: [
        {
          validator: charLenLimitConstructor(1, 200)
        }
      ]
    },
    {
      label: '输入样式',
      prop: 'fieldType',
      type: 'select',
      dicData: ALI_EXPRESS_TYPE_DIC,
      rules: [
        {
          required: true,
          message: requireFun('输入样式')
        }
      ],
      control(value) {
        const data = {
          valueRange: {
            hide: true
          },
          unit: {
            hide: true
          },
          multiValue: {
            hide: true
          }
        }

        if (!value) return data
        return {
          1: data,
          2: {
            multiValue: {
              hide: false
            },
            valueRange: {
              hide: true
            },
            unit: {
              hide: true
            }
          },
          3: data,
          4: {
            unit: {
              hide: false
            },
            valueRange: {
              hide: false
            },
            multiValue: {
              hide: true
            }
          }
        }[value]
      }
    },
    {
      label: '选项',
      prop: 'multiValue',
      rules: [
        {
          required: true,
          message: '选项必填'
        }
      ],
      placeholder: 'eg:普通订单：001，FBA订单：002，'
    },
    {
      label: '输入值范围',
      prop: 'valueRange',
      minProp: 'minValue',
      maxProp: 'maxValue',
      formslot: true,
  
      valueRangeRules: [
        {
          required: true,
          message: '输入值范围必填'
        },
        {
          validator: charLenLimitConstructor(1,17)
        }
      ],
      rules: [{ required: true }]
    },
    {
      label: '单位',
      prop: 'unit',
      type: 'select',
      dicData: ALI_UNIT_OPTION,
      rules: [
        {
          required: true,
          message: '单位必填'
        }
      ]
    },
    {
      label: '是否默认系统数据',
      prop: 'isSetDefaultCode',
      rules: [
        {
          required: true,
          message: requireFun('是否默认系统数据')
        }
      ],
      control(value) {
        return {
          isNeedDefaultValue: {
            hide: !!value
          },
          customDefaultValue: {
            hide: !!value
          },
          isAllowSystemCode: {
            hide: !!value
          }
        }
      }
    },
    {
      label: '设置是否可插入属性',
      prop: 'isAllowSystemCode',
      rules: [
        {
          required: true,
          message: requireFun('设置是否可插入属性')
        }
      ]
    },
    {
      label: '是否预设默认值',
      prop: 'isNeedDefaultValue',
      rules: [
        {
          required: true,
          message: requireFun('是否预设默认值')
        }
      ],
      control(value, form) {
        const hide = !value || !!form.isSetDefaultCode
        hide && (form['customDefaultValue'] = '')
        return {
          customDefaultValue: { hide }
        }
      }
    },
    {
      label: '预设值',
      prop: 'customDefaultValue',
      rules: [
        {
          required: true,
          message: requireFun('预设值')
        },
        {
          validator: charLenLimitConstructor(1,200)
        }
      ]
    }
  ]
}
