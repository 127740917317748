<template>
  <div class="app-container" v-loading.fullscreen.lock="loading">
    <breadCrumb />
    <BaseForm1 ref="form" class="mt20" :option="isAliExpress" v-model="form">
      <template #isSetDefaultCode>
        <el-radio-group v-model="form['isSetDefaultCode']">
          <el-radio v-for="{ label, value } in radioList" :key="label" :label="label">{{ value }}</el-radio>
        </el-radio-group>
        <setSysTemDialog ref="systemRef" @success="setData" :data="data" class="ml20" v-if="show" />
      </template>
      <template #isAllowSystemCode>
        <el-radio-group v-model="form['isAllowSystemCode']">
          <el-radio v-for="{ label, value } in radioList" :key="label" :label="label">{{ value }}</el-radio>
        </el-radio-group>
        <setInsertDialog
          ref="insertRef"
          @success="setData"
          :data="data"
          class="ml20"
          v-if="form['isAllowSystemCode'] == set"
        />
      </template>
      <template #isNeedDefaultValue>
        <el-radio-group v-model="form['isNeedDefaultValue']">
          <el-radio v-for="{ label, value } in radioList" :key="label" :label="label">
            {{ value }}
          </el-radio>
        </el-radio-group>
      </template>
      <template #customDefaultValue>
        <div class="flex-wrap">
          <el-input size="small" placeholder="请输入预设值" v-model="form['customDefaultValue']" clearable></el-input>
          <setAttributeDialog
            ref="attributeRef"
            class="ml20"
            v-if="form['isNeedDefaultValue'] == set"
            :data="data"
            @insertHandler="insertHandler"
          />
        </div>
      </template>
      <!-- 亚马逊-最大长度 -->
      <template #valueMaxLength>
        <el-input-number v-model="form.valueMaxLength" :precision="0" :step="1" :min="1" label="请输入最大长度"></el-input-number>
      </template>
      <!-- 速卖通-输入样式 -->
      <template #valueRange="{ column }">
        <rangeInput
          ref="rangeInput"
          v-model="form.valueRange"
          :prop="column.prop"
          :minProp="column.minProp"
          :maxProp="column.maxProp"
          :rules="column.valueRangeRules"
        ></rangeInput>
        <!-- <div class="valueRange flex">
          <el-input
            style="width: 48%"
            v-format:number="form.valueRange.minValue"
            v-model="form.valueRange.minValue"
            placeholder="请输入值"
            clearable
            size="medium"
            @input="valueRangeChange"
            @keyup.enter="valueRangeChange"
          ></el-input>
          <span class="valueRange-line">~</span>
          <el-input
            style="width: 48%"
            v-format:number="form.valueRange.maxValue"
            v-model="form.valueRange.maxValue"
            placeholder="请输入值"
            clearable
            size="medium"
            @change="valueRangeChange"
            @keyup.enter="valueRangeChange"
          ></el-input>
        </div> -->
      </template>
    </BaseForm1>
    <div class="button-group">
      <el-button type="primary" @click="clickHandler">保存</el-button>
      <el-button @click="back">取消</el-button>
    </div>
  </div>
</template>

<script>
import {
  TYPE_ALIEXPRESS_NUM,
  TYPE_AMAZON_NUM,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_URL,
  FIELD_TYPE_SELECT_INPUT
} from '@/utils/constant/fieldConst'
import baseForm1 from '@/components/base/baseForm1'
import breadCrumb from './module/breadCrumb'
import setSysTemDialog from './module/setSysTemDialog'
import setInsertDialog from './module/setInsertDialog'
import setAttributeDialog from './module/setAttributeDialog'
import { option, AliExpressOption } from './module/option'
import fieldApi from '@/api/productTemplate/fieldSettings'
import rangeInput from '@/views/components/form/rangeInput'
import { validData } from '@/components/avue/utils/util'


export default {
  name: 'addOrEditField',
  components: { breadCrumb, baseForm1, setSysTemDialog, setInsertDialog, setAttributeDialog, rangeInput },
  data() {
    return {
      option,
      AliExpressOption,
      set: 1, //  需要设置
      unset: 0, // 未设置
      form: {
        valueRange: {
          maxValue: '',
          minValue: ''
        },
        valueMaxLength:''
      },
      data: {},
      loading: false,
      radioList: [
        {
          label: 1,
          value: '是'
        },
        {
          label: 0,
          value: '否'
        }
      ]
    }
  },

  async created() {
    if (!this.isEdit) this.initAddData()
    else {
      await this.getDetail()
      this.initEditData()
    }
  },

  computed: {
    isEdit({ $route }) {
      return !!$route.query?.id
    },
    show({ form, set }) {
      return form['isSetDefaultCode'] == set
    },
    // 判断是否是速卖通
    isAliExpress({ $route, option, AliExpressOption }) {
      const { code } = $route.query
      return code == TYPE_ALIEXPRESS_NUM ? AliExpressOption : option
    }
  },

  methods: {
    // 输入样式值获取
    valueRangeChange() {
      let { maxValue, minValue } = this.form.valueRange
      if (!minValue) {
        minValue = maxValue
      }
      if (minValue && maxValue && Number(minValue) > Number(maxValue)) {
        // 交换最大最小值
        ;[minValue, maxValue] = [maxValue, minValue]
      }
      // this.$set(this.form.valueRange,'minValue',minValue)
      // this.$set(this.form.valueRange,'maxValue',maxValue)
    },
    setData(key, val) {
      this.form[key] = val
    },

    async getDetail() {
      const { id , code } = this.$route.query
      const fun = code == [TYPE_ALIEXPRESS_NUM] ? fieldApi.aliColumnMapDetail : fieldApi.detail
      const res = await awaitResolve(
        fun({
          id
        })
      )
      if (!res) return
      this.data = res.detail || {}
    },

    back() {
      this.$router.back()
    },

    valid() {
      if (this.form.isSetDefaultCode == this.set && !this.form.customfieldId) {
        this.$message.error('请先设置默认系统数据')
        return false
      }
      if (
        this.form.isSetDefaultCode != this.set &&
        this.form.isAllowSystemCode == this.set &&
        !this.form.customfieldIdList?.length
      ) {
        this.$message.error('请先设置可插入属性')
        return false
      }
      if (
        this.form.isSetDefaultCode != this.set &&
        this.form.isNeedDefaultValue == this.set &&
        !this.form.customDefaultValue
      ) {
        this.$message.error('请先设置预设值')
        return false
      }
      return true
    },

    async clickHandler() {
      try {
       await this.$refs.form.validate()
      } catch (error) {
        console.log('err', error)
        return false
      }

      const valid = this.valid()
      if (!valid) return
      const {
       valueRange: { maxValue, minValue },
        unit,
        multiValue,
        isSetDefaultCode,
        isAllowSystemCode,
        isNeedDefaultValue,
        customDefaultValue,
        excelLocalLabelName,
        excelFieldName,
        labelNameEn,
        labelNameEnTwo,
        columnGroupType,
        labelName,
        fieldType,
        valueFormatType,
        valueMaxLength,
        customfieldId,
        customfieldIds,
        customfieldIdList,
      } = this.form

      let data = [customfieldIdList]
      let arr

      if (customfieldIdList && data.length) {
        arr = data[0].split(',')
        if (arr.length) {
          arr = arr.map(Number)
        }
      }

      this.loading = true
      const { id, platType,code } = this.$route.query
      const {updateAliColumnMap, createAliColumnMap, update, add} = fieldApi
      const mappingList = {
        [TYPE_ALIEXPRESS_NUM]: this.isEdit ? updateAliColumnMap : createAliColumnMap,
        [TYPE_AMAZON_NUM]: this.isEdit ? update : add
      }
      const fun = mappingList[code]

      const res = await awaitResolve(
        fun({
          shopTypeId: platType,
          id: this.isEdit ? id : undefined,
          fieldType,
          valueFormatType,
          valueMaxLength,
          excelLocalLabelName,
          excelFieldName,
          maxValue,
          minValue,
          unit,
          multiValue,
          labelNameEn,
          labelNameEnTwo,
          labelName,
          columnGroupType,
          isNeedDefaultValue: isSetDefaultCode ? 0 : isNeedDefaultValue,
          isSetDefaultCode,
          isAllowSystemCode: isSetDefaultCode ? 0 : isAllowSystemCode,
          customDefaultValue,
          customfieldId,
          customfieldIds: validData(arr, customfieldIds)
        })
      )
      if (!res) return (this.loading = false)
      this.loading = false

      this.$message.success('操作成功')
      this.back()
    },

    initAddData() {
      this.form = {
        isSetDefaultCode: this.set,
        isAllowSystemCode: this.set,
        isNeedDefaultValue: this.set,
        customDefaultValue: '',
        valueRange: {
          maxValue: '',
          minValue: ''
        },
        unit: '',
        multiValue:'',
        valueFormatType:'',
        valueMaxLength:'',
      }
    },

    initEditData() {
      this.$refs.form.clearValidate()
      const {
        maxValue,
        minValue,
        unit,
        multiValue,
        isSetDefaultCode,
        isAllowSystemCode,
        isNeedDefaultValue,
        customDefaultValue,
        excelLocalLabelName,
        excelFieldName,
        labelNameEn,
        labelNameEnTwo,
        columnGroupType,
        labelName,
        fieldType,
        valueFormatType,
        valueMaxLength,
        customfieldIdList,
        customfieldId
      } = this.data
      let data = [customfieldIdList]
      let arr

      if (customfieldIdList && data.length) {
        arr = data[0].split(',')
        if (arr.length) {
          arr = arr.map(Number)
        }
      }
      this.form = {
        valueRange: {
          maxValue,
          minValue
        },
        unit,
        multiValue,
        excelLocalLabelName,
        excelFieldName,
        labelNameEn,
        labelNameEnTwo,
        columnGroupType,
        labelName,
        fieldType,
        valueFormatType,
        valueMaxLength: [
          FIELD_TYPE_SELECT,
          FIELD_TYPE_URL,
          FIELD_TYPE_SELECT_INPUT
        ].includes(+fieldType) ? '' : valueMaxLength, //判断输入样式（fieldType）选中下拉或图像valueMaxLength不传值
        isSetDefaultCode,
        isAllowSystemCode,
        isNeedDefaultValue,
        customDefaultValue,
        customfieldId,
        customfieldIds:arr
      }

      if (this.$refs.systemRef) this.$refs.systemRef.form.customfieldId = customfieldId
    },

    insertHandler({ variableName }) {
      this.form.customDefaultValue = this.form.customDefaultValue.concat(`{${variableName}}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.valueRange {
  justify-content: space-between;
  .valueRange-line {
    margin: 0 10px;
  }
}
.app-container {
  position: relative;
  .button-group {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 10px 1px #999;
    margin-left: -15px;
  }
  .flex-wrap {
    display: flex;
    flex-wrap: noWrap;
  }
}
</style>
